const headerData = [
    {
        label: "Game",
        path: "noku-games"
    },
    {
        path: "token",
        label: "Token",
    },
    {
        path: "marketplace",
        label: "Marketplace",
    },
    {
        path: "find-us",
        label: "Find us",
    },
    {
        path: "partners",
        label: "Partners",
    },
    {
        path: "social",
        label: "Social",
    },
];
export default headerData
