/** @jsx jsx */
import { jsx, Container, Flex, Box } from "theme-ui";
import { Link } from "../link";
import { Link as ScrollLink } from "react-scroll";
import Logo from "../logo";
import { DrawerProvider } from "../../contexts/drawer/drawer.provider";
import menuItems from "./header.data";
import logoWhite from "assets/logos/white.svg";

let backgroundColor = "transparent";

export default function Header({ className, color, scrollableLinks }) {
    backgroundColor = color ? color : backgroundColor;

    const styles = {
        headerBtn: {
            backgroundColor: "transparent",
            fontSize: ["12px", "12px", "16px"],
            fontWeight: "bold",
            letterSpacing: "-0.16px",
            textDecoration: "none",
            borderRadius: "5px",
            color: "#ffffff",
            border: "2px solid #fff",
            padding: "6.5px 24px",
            display: "inline-block",
            ml: ["0", null, null, "auto", "0"],
            mr: ["0", null, null, "20px", "0"],
            "&:hover": {
                color: "#fff",
            },
            "@media screen and (max-width: 639px)": {
                width: "120px",
                height: "32px",
                display: "flex",
                justifyContent: "center",
                padding: "2px 0",
            },
        },
        cryptoBtn: {
            backgroundColor: "white",
            fontSize: "16px",
            fontWeight: "bold",
            letterSpacing: "-0.16px",
            textDecoration: "none",
            borderRadius: "5px",
            color: "var(--theme-ui-colors-secondary)",
            border: "2px solid #fff",
            padding: "6.5px 24px",
            display: ["none", null, null, null, "inline-block"],
            ml: "10px",
            mr: ["0", null, null, "20px", "0"],
            "&:hover": {
                color: "#fff",
            },
        },

        header: {
            color: "text_white",
            fontWeight: "normal",
            py: "25px",
            width: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor,
            transition: "all 0.4s ease",

            "&.sticky": {
                backgroundColor: "#4446ed",
                color: "text",
                py: "15px",
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.06)",
            },
        },
        container: {
            display: "flex",
            alignItems: "center",
            "@media screen and (max-width: 960px)": {
                justifyContent: "space-between",
            },
        },
        nav: {
            mx: "auto",
            "@media screen and (max-width: 960px)": {
                display: "none",
            },
            navLink: {
                fontSize: "16px",
                color: "#fff",
                fontWeight: "400",
                cursor: "pointer",
                lineHeight: "1.2",
                mr: "48px",
                transition: "500ms",
                ":last-child": {
                    mr: "0",
                },
                "&:hover": {
                    color: "primary",
                },
            },
        },
    };

    if (scrollableLinks === undefined) {
        scrollableLinks = true;
    }

    return (
        <DrawerProvider>
            <header sx={styles.header} className={className}>
                <Container sx={styles.container}>
                    <Logo width="106px" height="30px" image={logoWhite} />

                    <Flex as="nav" sx={styles.nav}>
                        {menuItems.map(({ path, label }, i) =>
                            scrollableLinks ? (
                                <ScrollLink
                                    sx={styles.nav.navLink}
                                    to={path}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    key={i}
                                >
                                    {label}
                                </ScrollLink>
                            ) : (
                                <Link
                                    sx={styles.nav.navLink}
                                    to={`/#${path}`}
                                    key={i}
                                >
                                    {label}
                                </Link>
                            )
                        )}
                    </Flex>

                    <Box
                        as="a"
                        target="_blank"
                        href="https://wallet.noku.io"
                        ml={2}
                        sx={styles.headerBtn}
                        variant="buttons.primary"
                    >Connect Wallet</Box>
                </Container>
            </header>
        </DrawerProvider>
    );
}
